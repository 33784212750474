import { FiEye } from '@react-icons/all-files/fi/FiEye';
import { FiEyeOff } from '@react-icons/all-files/fi/FiEyeOff';
import { Link } from '@remix-run/react';
import { useState } from 'react';
import { Button } from '@/components/button';

export const InputPassword = ({ setPassword }: { setPassword: (password: string) => void }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div>
      <div className="mt-4 flex h-16 w-full flex-col text-xs leading-snug tracking-wide whitespace-nowrap">
        <div className="leading-none font-semibold text-neutral-500">パスワード</div>
        <div className="text-glyphs-normal outline-border focus-within:outline-active mt-2 flex w-full items-center gap-1 rounded-sm bg-white px-3 py-2 text-base tracking-wider outline-1 focus-within:outline-2">
          <input
            required
            className="w-full focus:outline-hidden"
            name="password"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            className="flex h-[16px] cursor-pointer items-center justify-around"
            color="glyphs_normal"
            size="free"
            type="button"
            variant="transparent"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <FiEyeOff className="my-auto aspect-square w-6 shrink-0 self-stretch object-contain" />
            ) : (
              <FiEye className="my-auto aspect-square w-6 shrink-0 self-stretch object-contain" />
            )}
          </Button>
        </div>
      </div>
      <div className="text-xs leading-snug tracking-wide whitespace-nowrap">
        <div className="mt-2 flex w-full items-start gap-2">
          <Link className="text-right text-indigo-500" to="/forgot_password">
            パスワードをお忘れですか？
          </Link>
        </div>
      </div>
    </div>
  );
};
